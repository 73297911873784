/* eslint-disable react/no-unescaped-entities */
import Image from 'next/image'
import classnames from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container } from '@components/Container/Container'
import styles from './about.module.scss'

export const About = () => {
  return (
    <section id="about" className={styles.wrapper}>
      <Container additionalStyles={classnames(styles.about)}>
        <div className={styles.bio}>
          <h3 className={styles.pre}>ABOUT ME</h3>
          <h2 className={styles.title}>Who I am and what I do</h2>
          <p>Hi, I'm Mario Veltri!</p>
          <p>
            With over 22 years of experience in design and creative direction,
            my career spans art direction, publishing, illustration, 3D, graphic
            design, CGI, UX/UI, video games, and VFX.
          </p>
          <p>
            I’ve had the privilege of designing successful magazines,
            contributing illustrations featured in international books and
            publications, and leading a team of 16 as an art director.
          </p>
          <p>
            My open-minded approach drives me to learn from various disciplines
            and seek out environments where I can grow by collaborating with
            talented individuals.
          </p>
          <p>
            I thrive on productivity and teamwork, finding innovative solutions
            to challenges and thinking beyond conventional boundaries.
          </p>
          <p>
            I believe a great creative leader bridges the gap between ideas and
            execution—a skill honed through dedication and years of experience.
          </p>
          <p>
            My motto is: “love what you do, do what you love, and never stop
            dreaming.”
          </p>
          <AnchorLink
            href="#contacts"
            className={classnames('linkAsBtn', 'button')}
          >
            CONTACT ME
          </AnchorLink>
        </div>
        <div className={styles.image}>
          <Image
            alt="Mario Veltri"
            src={`/mario_veltri.jpg`}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>
      </Container>
    </section>
  )
}
