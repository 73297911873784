import Head from 'next/head'
import { AppProps } from 'next/app'
import { Header } from '@components/Header/Header'
import { Footer } from '@components/Footer/Footer'
import { ScrollTop } from '@components/ScrollTop/ScrollTop'
import { GoogleFonts } from 'next-google-fonts'
import '@styles/globals.scss'

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <GoogleFonts href="https://fonts.googleapis.com/css2?family=Bitter&display=swap" />
      <GoogleFonts href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;800&display=swap" />
      <Head>
        <title>Mario Veltri - Creative Director</title>
        <meta
          name="description"
          content="Mario Veltri is a versatile Creative Director with over 20 years of experience in several fields including: Graphic Design, Animation, CGI and VFX."
        />
        <meta
          name="keywords"
          content="UI Artist, UX/UI, Art Director, Creative Director, Lead Artist, Head of UI, Photoshop, Illustrator, Maya, After Effect, Unity, 3D, Vector Graphics, Game Assets, Animation, Mario Veltri"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="30 days" />
        <meta name="author" content="idrinkritalin" />
      </Head>
      <Header />
      <Component {...pageProps} />
      <ScrollTop />
      <Footer />
    </>
  )
}

export default App
