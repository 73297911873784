import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import * as contentful from 'contentful'

const space = process.env.CONTENTFUL_SPACE_ID
const accessToken = process.env.CONTENTFUL_API_KEY

const client = contentful.createClient({
  space,
  accessToken,
})

export const fetchPortfolioItems = async () => {
  try {
    const res = await client.getEntries()
    if (!res.items) {
      console.error(`Error fetching items`)
      return null
    }
    return res.items
  } catch (err) {
    console.error('Error fetching from Contentful', err)
    return null
  }
}

export const fetchPortfolioItem = async (id: string) => {
  try {
    const res = await client.getEntry(id)
    if (!res) {
      console.error(`Error fetching item`)
      return null
    }
    return res
  } catch (err) {
    console.error('Error fetching from Contentful', err)
    return null
  }
}

export const fetchPortfolioCategory = async (category: string) => {
  try {
    const res = await client.getEntries({
      content_type: 'title',
      'fields.category[in]': category,
    })
    if (!res.items) {
      console.error(`Error fetching this category`)
      return null
    }
    return res.items
  } catch (err) {
    console.error('Error fetching from Contentful', err)
    return null
  }
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) => `${next(node.content)}`,
    [INLINES.HYPERLINK]: (node, next) => `${next(node.content)}`,
  },
}

export const formatDescription = (description) =>
  documentToHtmlString(description, options)
